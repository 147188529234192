html {
  scroll-behavior: smooth;
  font-family: 'Karla', sans-serif;
}

body {
  margin: 0;
  font-family: 'Karla', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center-row {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.vertical-align-content > * {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

a {
  /* all: unset; */
  
  text-decoration: none;
  color: #000;
}
