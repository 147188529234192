h1, h2, h3, h4, h5, h6 {
    font-family: 'Lusitana', serif;
}

h1 {
    font-size: 4.4rem;
}

body, p {
    margin: 0;
    font-family: 'Karla', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

a {
    /* all: unset; */
    
    text-decoration: none;
    color: #000;
}

ion-button {
    --color: #000;
    --background: transparent;
    --background-activated: transparent;
    --color-focused: transparent;
}